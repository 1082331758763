








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import ToolCategory from '@/shared/modules/tool-category/models/tool-category.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import Field from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import _ from 'lodash'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class ToolCategoriesEdit extends Vue {
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.uuid)
      .setEndpoint(`company/${ this.company.slug }/tool-categories`)
      .setMethod(HttpMethod.PUT)
      .setModel(ToolCategory)
      .setInjectValues({
        company_uuid: this.company.uuid
      })
      .setFields([
        new Field()
          .setKey('name')
          .setTitle(__('company.views.tool-categories.form.details.name'))
          .isRequired()
      ])
      .setSubmit({
        text: __('company.views.tool-categories.edit.form.submit')
      })
      .setOnSuccess(this.onSuccess)
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.toolCategoriesIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.tool-categories.edit.form.on-success'),
    })
    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
